import 'core-js/modules/es.object.from-entries';

import ReactOnRails from 'react-on-rails';
import { hydrate } from 'react-dom';
import { loadableReady } from '@loadable/component';

import { objectMap } from '../bundles/utils';
import loadable, { retry } from '../bundles/utils/loadable';

import '../bundles/assets/helpers/reset.scss';

import './polyfills';

if (!window.location.pathname.startsWith('/clients')) {
  retry(() => import('bootstrap/dist/css/bootstrap.css')).catch(() => {
    // skip
  });
}

const ErrorPage = loadable(() => import('../bundles/components/ErrorPage'));
const NotSupportedPage = loadable(() => import('../bundles/components/NotSupportedPage'));
const GlobalRoutes = loadable(() => import('../bundles/containers/Routes'));
const ClientsWidgetComponent = loadable(() => import('../bundles/components/ClientsWidgetComponent'));
const FinPromptChatBot = loadable(() => import('../bundles/components/FinPromptProject'));
// Closing while RBI not needed
// const Header = loadable(() => import('../bundles/components/Header'));
// const RBISearch = loadable(() => import('../bundles/containers/RBISearch');
// const RBIWatchlists = loadable(() => import('../bundles/containers/RBIWatchlists');
// const RBILogin = loadable(() => import('../bundles/components/RBI/RBILogin');
// const UsersSignIn = loadable(() => import('../bundles/containers/UsersSignIn'));

const loadableReadyWrapper = (Component, props, railsContext, domId) => {
  loadableReady(() => {
    const root = document.getElementById(domId);
    hydrate(<Component {...props} railsContext={railsContext} />, root);
  });
};

ReactOnRails.register(
  objectMap({
    GlobalRoutes,
    // Closing while RBI not needed
    // Header,
    // RBISearch,
    // RBIWatchlists,
    // RBILogin,
    // UsersSignIn,
    ErrorPage,
    NotSupportedPage,
    ClientsWidgetComponent,
    FinPromptChatBot,
  }, (Component) => (
    (props, railsContext, domId) => {
      loadableReadyWrapper(Component, props, railsContext, domId);
    }
  )),
);
