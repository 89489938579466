export function loadScript(src, async = false) {
  const script = document.createElement('script');
  script.async = async;
  script.src = src;
  script.crossorigin = true;

  return script;
}

export function uuid() {
  return Math.random().toString(36).split('.').pop();
}

export const objectMap = (source, callback) => (
  Object.fromEntries(
    Object.entries(source).map(([key, value], index, array) => (
      [key, callback(value, index, array)]
    )),
  )
);
