import loadableBase from '@loadable/component';

const consoleDebug = (fn) => {
  if (typeof console.debug !== 'undefined') {
    console.debug(fn());
  }
};

export const retry = (fn, retriesLeft = 5, interval = 250) => (
  new Promise((resolve, reject) => {
    (typeof fn === 'function' ? fn() : fn.requireAsync())
      .then(resolve)
      .catch(() => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            console.warn('Maximum retries exceeded. Reloading page...');
            window.location.reload();
            return;
          }
          // Passing on "reject" is the important part
          consoleDebug(() => `Trying request, retriesLeft: ${retriesLeft - 1}`);
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  })
);

const loadable = (fn, ...args) => loadableBase(
  typeof window !== 'undefined' ? () => retry(fn) : fn,
  ...args,
);

export default loadable;
